<template>
  <div>
    <b-card no-body>
      <Tabs>
        <Tab v-for="(buyer, key) in table_data" :key="key" :title="key">
          <table :class="`w-100 table--perweek table__border--${table_style}`">
            <thead :class="`table__head--${table_style}`">
              <tr>
                <th class="table__head--text">Foto</th>
                <th class="table__head--text">Item</th>
                <th
                  v-if="checkroles(['lev_zien'], msalInstance.getAllAccounts()[0].idTokenClaims.roles)"
                  class="table__head--text"
                >
                  Leverancier
                </th>
                <th class="table__head--text">Ontvangst</th>
                <th
                  v-if="checkroles(['marges_zien'], msalInstance.getAllAccounts()[0].idTokenClaims.roles)"
                  class="table__head--text"
                >
                  Inkoop
                </th>
                <th
                  v-if="checkroles(['marges_zien'], msalInstance.getAllAccounts()[0].idTokenClaims.roles)"
                  class="table__head--text"
                >
                  Kosten
                </th>
                <th class="table__head--text">Status</th>
                <th
                  v-if="checkroles(['marges_zien'], msalInstance.getAllAccounts()[0].idTokenClaims.roles)"
                  class="table__head--text"
                >
                  Vraagprijs
                </th>
                <th
                  v-if="checkroles(['marges_zien'], msalInstance.getAllAccounts()[0].idTokenClaims.roles)"
                  class="table__head--text"
                >
                  Netto
                </th>
                <th class="table__head--text">Gereserveerd</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, key) in buyer"
                :key="key"
                :class="`table__row--color`"
              >
                <td class="table__cell--default">
                  <img
                    :src="
                      `https://apps.kleyn.com:1919/prod/public/data/mainimage/${item.foto}/original`
                    "
                    :class="
                      `m-1 border border-secondary table__picture--max-width`
                    "
                  />
                </td>

                <td class="table__cell--default">
                  <div>
                    <ImageHover :itemnummer="item.nummer" :bu="item.bu" />
                    {{ item.SoortNaam }}<br />
                    {{ item.MerkNaam }}<br />
                    {{ item.Typenaam }}<br />
                    {{ item.regjaar }}<br />
                    <b-button v-b-toggle="`${item.nummer}`" class="m-1"
                      >Specificaties</b-button
                    >
                    <b-collapse
                      :id="`${item.nummer}`"
                      class="position-absolute "
                    >
                      <b-card class="p-3 border-secondary"
                        >{{ item.euro }}<br />
                        {{ item.configuratie }}<br />
                        {{ item.kraan }}<br />
                        {{ item.cabine }}<br />
                        {{ item.retarder }}<br />
                        {{ item.abs }}<br />
                        {{ item.opmerkingenintern }}</b-card
                      >
                    </b-collapse>
                  </div>
                </td>
                <td
                  v-if="checkroles(['lev_zien'], msalInstance.getAllAccounts()[0].idTokenClaims.roles)"
                  class="table__cell--default"
                >
                  <div>
                    {{ item.leverancier }}<br />
                    {{ item.levnaam }}<br />
                    {{ item.levadres }}<br />
                    {{ item.levstad }}<br />
                    {{ item.levland }}
                  </div>
                </td>
                <td class="table__cell--default">{{ item.ontvangstdatum }}</td>
                <td
                  v-if="checkroles(['marges_zien'], msalInstance.getAllAccounts()[0].idTokenClaims.roles)"
                  class="table__cell--default"
                >
                  € {{ item.inkoopbedrag }}
                </td>
                <td
                  v-if="checkroles(['marges_zien'], msalInstance.getAllAccounts()[0].idTokenClaims.roles)"
                  class="table__cell--default"
                >
                  € {{ item.kostenbedrag }}
                </td>
                <td class="table__cell--default">{{ item.status }}</td>
                <td
                  v-if="checkroles(['marges_zien'], msalInstance.getAllAccounts()[0].idTokenClaims.roles)"
                  class="table__cell--default"
                >
                  € {{ item.vraagprijs }}
                </td>
                <td
                  v-if="checkroles(['marges_zien'], msalInstance.getAllAccounts()[0].idTokenClaims.roles)"
                  class="table__cell--default"
                >
                  € {{ item.nettoprijs }}
                </td>
                <td class="table__cell--default" v-if="item.gereserveerd">
                  {{ item.gereserveerd }}
                </td>
                <td class="table__cell--default" v-else>Niet gereserveerd</td>
              </tr>
            </tbody>
          </table>
        </Tab>
      </Tabs>
    </b-card>
  </div>
</template>

<script>
import ImageHover from "@/components/ImageHover.vue";
import Tabs from "@/components/Tabs/Tabs.vue";
import Tab from "@/components/Tabs/Tab.vue";

export default {
  props: {
    table_data: [Array, Object],
    table_style: String,
  },
  components: { ImageHover, Tabs, Tab },
};
</script>

<template>
  <div :class="`m-3 wrapper  wrapper--${bu}`">
    <div class="text-center p-2">
      <h2>Ontvangsten per week</h2>
      <span @click="previousWeek"><i class="fas fa-arrow-left"></i></span>
      <span class="text-center m-2"
        >Week: {{ week_number }}, {{ year_number }}</span
      >
      <span @click="nextWeek"><i class="fas fa-arrow-right"></i></span>
    </div>
    <Loading v-if="loading" />
    <div v-else>
      <button class="m2 employee_download" @click="createExcel">Ontvangstlijst uitdraai</button>
      <RecievedPerWeekTable
        :table_data="recieved_per_week"
        :table_style="`${bu}`"
      />
    </div>
  </div>
</template>

<script>
import RecievedPerWeekTable from "@/components/RecievedPerWeekTable.vue";
import {
  subtractTime,
  addTime,
  getWeekNumber,
  getYearNumber,
} from "@/functions/date.js";
import moment from "moment";
import Loading from "@/components/Loading.vue";
import request from "@/functions/request.js";
import * as XLSX from "xlsx";

export default {
  props: ["bu"],
  components: { RecievedPerWeekTable, Loading },
  data: () => ({
    loading: true,
    full_date: null,
    year_number: null,
    week_number: null,
    recieved_per_week: null,
    excel_export_data: null,
  }),
  created() {
    this.full_date = moment();
    this.getData(this.bu);
  },
  watch: {
    bu(newbu) {
      this.loading = true;
      this.getData(newbu);
    },
  },
  methods: {
    getData(bu) {
      this.loading = true;
      this.year_number = getYearNumber(this.full_date);
      this.week_number = getWeekNumber(this.full_date);
      request(
        `recieved-per-week/${this.year_number}/${this.week_number}/${bu}`,
        "GET"
      ).then(({ recieved_per_week, excel_export_data }) => {
        this.recieved_per_week = recieved_per_week;
        this.excel_export_data = excel_export_data;
        this.loading = false;
      });
    },

    previousWeek() {
      this.full_date = subtractTime(7, this.full_date, "days");
      this.getData(this.bu);
    },

    nextWeek() {
      this.full_date = addTime(7, this.full_date, "days");
      this.getData(this.bu);
    },

    createExcel() {
      this.recieved_ordes = [];

      this.excel_export_data.forEach((i) => {
        this.recieved_ordes.push({
          "Verkopernaam": i.naam,
          "Nummer": i.nummer,
          "Soortnaam": i.SoortNaam,
          "Opbouwnaam": i.OpbouwNaam,
          "Merktype": i.MerkNaam,
          "Typenaam": i.Typenaam,
          "Euro": i.euro,
          "Configuratie": i.configuratie,
          "Kraan": i.kraan,
          "Cabine": i.cabine,
          "Retarder": i.retarder,
          "ABS": i.abs,
          "Consignatie": i.consignatie,
          "Regjaar": Number(i.regjaar),
          "Status": i.status,
          "Inkoopbedrag": Number(i.inkoopbedrag.replace(/,/g, '')),
          "Kostenbedrag": Number(i.kostenbedrag.replace(/,/g, '')),
          "Vraagprijs": Number(i.vraagprijs.replace(/,/g, '')),
          "Nettoprijs": Number(i.nettoprijs.replace(/,/g, '')),
          "Ontvangstdatum": i.ontvangstdatum,
          "Leveringsvoorwaarde": i.leveringsvoorwaarde,
          "Leverancier": i.leverancier,
          "Lev naam": i.levnaam,
          "Lev adres": i.levadres,
          "Lev stad": i.levstad,
          "Lev land": i.levland,



        });
      });

      var telWS = XLSX.utils.json_to_sheet(this.recieved_ordes);
      var wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(
        wb,
        telWS,
        `ontvangsten_${this.bu}_${this.year_number}_${this.week_number}`
      );
      XLSX.writeFile(
        wb,
        `ontvangsten_${this.bu}_${this.year_number}_${this.week_number}.xlsx`
      );
    }
  },
};
</script>
